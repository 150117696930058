<template>
  <div class="substance">
    <div class="container">
      <div class="substance__title">Добавить вещество</div>
      <AddNewSubstance />
    </div>
  </div>
</template>

<script>
import AddNewSubstance from '../components/AddNewSubstance'

export default {
  name: 'Substance',
  components: {AddNewSubstance},
  data() {
    return {
/*       carbonNumber: '',
      hydrogenNumber: '',
      oxygenNumber: '',
      halogenNumber: '',
      molarMass: '',
      specificHeatOfCombustion: '',
      bottomConcentrationLimit: '',
      fuelClass: '',
      massBurningRate: '',
      substanceDensity: '',
      constA: '',
      constB: '',
      constCa: '',
      flashPoint: '',
      adiabaticExponent: '',
      boilingTemperature: '',
      specificHeat: '',
      specificHeatOfVaporization: '',
      criticalPressure: '',
      criticalTemperature: '',
      classOfSubstanceJetFire: '',
      nameOfSubstancePoolFire: '', */
    }
  },
  // некоторые поля зависят от выбранного ранее класса ТО и агрегатного состояния
}
</script>

<style lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

  .substance {
    padding: 6rem 0 12rem;

    &__title {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.8rem;
      border-bottom: 2px solid $darkGrey;
      padding-bottom: 6.2rem;
    }

    &__form {
      &-button {
        margin-top: 6rem;
        display: flex;
        justify-content: center;
      }

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $lightGrey;
        padding: 2rem 0;
      }

      &-label {
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &-input {
        width: 24rem;
      }

      &-text {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.5rem;
        margin: 3rem 0;
      }
    }
  }
</style>
